/**
 * @typedef {Object} ProductListing
 * @property {string} title - The title of the product.
 * @property {string} link - The URL link for the product.
 * @property {Date} published - The date when the product was published.
 * @property {string} description - A description of the product.
 * @property {string} msrp - The manufacturer's suggested retail price.
 * @property {string} price - The current price of the product.
 * @property {string} previous_price - The previous price of the product.
 * @property {string} img - The URL of the product image.
 * @property {string} cart - The URL to add the product to the cart.
 * @property {string} condition - The condition of the product.
 */

/**
 * Creates a ProductListing object ensuring proper field types.
 *
 * @param {Object} data - The raw data object.
 * @param {string} data.title
 * @param {string} data.link
 * @param {string|Date} data.published - Can be a date string or Date object.
 * @param {string} data.description
 * @param {string} data.msrp
 * @param {string} data.price
 * @param {string} data.previous_price
 * @param {string} data.img
 * @param {string} data.cart
 * @param {string} data.condition
 * @returns {ProductListing} A well-formed ProductListing object.
 */
function createProductListing(data) {
    return {
        title: data.title,
        link: data.link,
        published: data.published instanceof Date ? data.published : new Date(data.published),
        description: data.description,
        msrp: data.msrp,
        price: data.price,
        previous_price: data.previous_price,
        img: data.img,
        cart: data.cart,
        condition: data.condition,
    };
}

export default createProductListing;

// Example usage:
// const rawData = {
//     title: "Example Product",
//     link: "https://example.com/product",
//     published: "2023-10-10T12:00:00Z",
//     description: "This is an example product.",
//     msrp: "$100",
//     price: "$80",
//     previous_price: "$90",
//     img: "https://example.com/product.jpg",
//     cart: "https://example.com/cart",
//     condition: "new",
// };
//
// const productListing = createProductListing(rawData);
// console.log(productListing);