import React, { useEffect, useState } from 'react';
import Card from './Card';
import './Fresh.css';
import createProductListing from "./models/ProductListing";

function Fresh() {
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('/api/fresh')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not OK');
                }
                return response.json();
            })
            .then(data => {
                const validListings = data.body.map(item => createProductListing(item));
                setListings(validListings);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching listings:', error);
                setError(error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <p>Loading fresh listings...</p>;
    }

    if (error) {
        return <p>Error loading listings: {error.message}</p>;
    }

    return (
        <div className="container">
            <h1>Fresh</h1>
            {listings.length === 0 ? (
                <p>No listings available.</p>
            ) : (
                <div className="grid">
                    {listings.map(item => (
                        <Card key={item.link} item={item} />
                    ))}
                </div>
            )}
        </div>
    );
}

export default Fresh;