import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import pako from 'pako';

const CompositionDisplay = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        HTMLBody: '',
    });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Helper function to convert a base64 string to a Uint8Array
    const base64ToUint8Array = (base64) => {
        const binaryString = atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    };

    useEffect(() => {
        const fetchComposition = async () => {
            setLoading(true);
            const response = await fetch(`/api/c/${id}`);
            if (!response.ok) {
                if (response.status === 404) {
                    throw new Error('Composition not found');
                }
                throw new Error('Failed to fetch');
            }
            const data = await response.json();

            // Retrieve the compressed HTML from the "body" field
            const compressedBody = data.body;
            if (!compressedBody) {
                throw new Error('HTML content not available');
            }

            // Convert the base64 encoded string into a Uint8Array
            const compressedBytes = base64ToUint8Array(compressedBody);

            // Decompress using pako; ‘to: string’ ensures the output is a string
            let decompressedHTML = '';
            try {
                decompressedHTML = pako.ungzip(compressedBytes, { to: 'string' });
            } catch (decompressError) {
                console.error('Error decompressing HTML content:', decompressError);
                throw new Error('Failed to decompress HTML content');
            }

            // Update state with both the compressed data and the decompressed HTML content
            setFormData({
                HTMLBody: decompressedHTML,
            });
            setLoading(false);
        };

        fetchComposition().catch((error) => {
            setError(error.message);
            setLoading(false);
        });
    }, [id]);

    if (loading) {
        return (
            <div className="composition-container">
                <p>Loading...</p>
            </div>
        );
    }

    if (error === 'Composition not found') {
        return (
            <div className="composition-container">
                <p>
                    This composition was viewable online for one month.
                    To see the latest used items for sale at Hawthorne Stereo,
                    head over to the <a href="https://hawthornestereo.com/fresh">used page</a>!
                </p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="composition-container">
                <p>Unexpected Error: {error}</p>
            </div>
        );
    }

    return (
        <div className="composition-container">
            <div dangerouslySetInnerHTML={{ __html: formData.HTMLBody }} />
        </div>
    );
};

export default CompositionDisplay;
