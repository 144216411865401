import React from 'react';
import PropTypes from 'prop-types';
import './Fresh.css';

function Card({ item }) {
    return (
        <div className="card">
            <a href={item.link} target="_blank" rel="noopener noreferrer">
                {item.img && (
                    <img src={item.img} alt={item.title} className="card-img" />
                )}
            </a>
            <div className="card-body">
                <a href={item.link} className="card-title-link" target="_blank" rel="noopener noreferrer">
                    <h2 className="card-title">{item.title}</h2>
                </a>
                <p className="card-description">{item.description}</p>
                {item.previous_price ? (
                    <p className="price">
                        Price:{" "}
                        <span style={{ textDecoration: "line-through" }}>
                            {item.previous_price}
                        </span>
                        <span style={{ fontWeight: "bold", marginLeft: "8px" }}>
                            PRICE DROP
                        </span>
                        <span style={{ marginLeft: "8px" }}>{item.price}</span>
                    </p>
                ) : (
                    <p className="price">Price: {item.price}</p>
                )}
                <div className="card-links">
                    <a href={item.cart} className="btn" target="_blank" rel="noopener noreferrer">
                        Add to Cart
                    </a>
                </div>
            </div>
        </div>
    );
}

Card.propTypes = {
    item: PropTypes.shape({
        title: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        published: PropTypes.instanceOf(Date).isRequired,
        description: PropTypes.string.isRequired,
        msrp: PropTypes.string,
        price: PropTypes.string.isRequired,
        previous_price: PropTypes.string,
        img: PropTypes.string.isRequired,
        cart: PropTypes.string.isRequired,
        condition: PropTypes.string.isRequired,
    }).isRequired,
};

export default Card;